<!--
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Dyf
 * @LastEditTime: 2023-06-29 17:46
 * @Description: 
-->
<style lang="scss" scoped>
.page-home {
  @include innerPage(22px 0 48px);
  @include flexBox(space-between, flex-start);

  .left-box {
    width: 62.68%;
    height: 100%;
    position: relative;

    .bitmap {
      position: absolute;
      top: -75px;
      left: 73.5%;
      z-index: 1;
    }

    .data-total {
      width: 100%;
      flex-wrap: wrap;
      @include flexBox(space-between, flex-start);

      &-item {
        width: calc((100% - 30px) / 4);
        height: 152px;
        background: #fff;
        margin-bottom: 12px;
        border-radius: 10px;
        position: relative;
        z-index: 2;
        box-sizing: border-box;
        padding-left: 4%;
        color: #fff;
        @include flexBox;

        .data-total-type {
          position: relative;
          z-index: 2;
          @include flexBox(center, flex-start);
          flex-direction: column;

          span {
            font-size: 16px;
          }

          p {
            font-size: 16px;
            line-height: 2em;
          }
        }

        .icon {
          width: 209px;
          height: 210px;
          border-radius: 20px;
          position: absolute;
          bottom: -34px;
          right: -56px;
          @include flexBox(center);
        }

        &.empty {
          background: transparent;
        }

        &:nth-child(1) {
          @include dotBgOrangered;
        }

        &:nth-child(2) {
          @include dotBgDarkblue;
        }

        &:nth-child(3) {
          z-index: 0;
          @include dotBgGaryblue;
        }

        &:nth-last-child(1) {
          @include dotBgGreen;
        }

        &:nth-last-child(2) {
          @include dotBgPurple;
        }

        &:nth-last-child(3) {
          @include dotBgOrange;
        }

        &:nth-last-child(4) {
          @include dotBgGarygreen;
        }
      }
    }

    .ranking-table {
      width: 100%;
      height: calc(100% - 354px);
      border-radius: 15px;
      background: #f7f7ff;
      margin-top: 26px;
      overflow: hidden;

      .box-head {
        padding-left: 62px;

        &::before {
          left: 40px;
        }

        &::after {
          left: 32px;
        }
      }

      .table {
        &-box {
          width: 100%;
          height: calc(100% - 72px);
          box-sizing: border-box;
          padding: 24px 8px 30px 0;

          .avatar {
            width: 58px;
            height: 58px;
            border-radius: 50%;

            img {
              width: 58px;
              height: 58px;
              border: 1px solid #fff;
              border-radius: 50%;
              object-fit: cover;
            }

            &.top-three {
              width: 68px;
              height: 70px;
              border-radius: 0;
              border: none;
              padding-left: 10px;
              padding-top: 12px;
              background-repeat: no-repeat;
              background-size: 100% auto;
              box-sizing: border-box;
            }

            &.ranking-level {
              &--1 {
                background-image: url("~@assets/images/ranking-1st.png");
              }

              &--2 {
                background-image: url("~@assets/images/ranking-2nd.png");
              }

              &--3 {
                background-image: url("~@assets/images/ranking-3rd.png");
              }
            }
          }
        }

        &-head {
          height: 50px;
          box-sizing: border-box;
          padding: 0 40px 0 48px;
          position: relative;
          z-index: 1;
          @include flexBox;

          h5 {
            line-height: 50px;
            color: #363636;
            text-align: center;
          }
        }

        &-body {
          height: calc(100% - 50px);
          overflow: hidden;
          overflow-y: auto;
          box-sizing: border-box;
          padding: 0 40px 0 48px;

          &.scroll {
            padding-right: 32px;
          }
        }

        &-row {
          height: 85px;
          border-radius: 15px;
          overflow: hidden;
          background: #eff3f9;
          margin-bottom: 10px;
          color: #464646;
          @include flexBox;

          &:hover {
            width: calc(100% + 28px);
            transform: translateX(-28px);
            background: #6c4ecb;
            color: #fff;
          }
        }

        &-cell {
          width: 100%;

          * {
            @include flexBox(center);
          }

          p {
            margin: 0 auto;
          }

          &:nth-child(1) {
            width: 12%;
          }

          &:nth-child(2) {
            width: 29%;
          }

          &:nth-child(3) {
            width: 28%;
          }

          &:nth-last-child(1) {
            width: 18%;
          }

          &:nth-last-child(2) {
            width: 13%;
          }
        }
      }
    }
  }

  .right-box {
    width: 35.78%;
    height: 100%;
    background: #fff;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    overflow: hidden;

    .box-head {
      padding-left: 48px;

      &::before {
        left: 30px;
      }

      &::after {
        left: 22px;
      }
    }

    .notify {
      &-box {
        width: 100%;
        height: calc(100% - 72px);
        box-sizing: border-box;
        padding: 12px 8px 30px 30px;

        .no-data--empty {
          top: 40%;

          img {
            width: 180px;
          }
        }
      }

      &-list {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding-right: 22px;
      }

      &-item {
        height: 108px;
        box-sizing: border-box;
        padding-top: 10px;
        flex-direction: column;
        border-bottom: 1px solid rgba($color: #a2a2a2, $alpha: 0.17);
        cursor: pointer;
        @include flexBox(center, flex-start);

        .date {
          @include flexBox;
          color: #949495;

          .iconfont {
            font-size: 18px;
            color: #666;
          }
        }

        .desc {
          width: 80%;
          font-size: 16px;
          height: 2em;
          line-height: 2em;
          color: #2e2e2e;
        }

        &:hover {
          .date,
          .desc {
            color: #6c4ecb;

            .iconfont {
              color: #6c4ecb;
            }
          }
        }

        &:last-child {
          border-bottom-color: transparent;
        }
      }
    }
  }

  .box-head {
    width: 100%;
    height: 72px;
    background: #f6f7fc;
    box-sizing: border-box;
    position: relative;
    @include flexBox(space-between);

    &::before,
    &::after {
      content: "";
      width: 11px;
      height: 11px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      background: #6c4ecb;
    }

    &::after {
      background: rgba($color: #feaf2f, $alpha: 0.72);
    }

    .view-all {
      font-size: 14px;
      color: #a2a2a2;
      margin-right: 30px;
      height: 2em;
      @include flexBox;

      .iconfont {
        color: #474747;
      }

      &:hover {
        color: #6c4ecb;

        .iconfont {
          color: #6c4ecb;
        }
      }
    }
  }

  .no-data--empty {
    img {
      width: 160px;
    }
  }
}
</style>

<template>
  <section class="page-home">
    <div class="left-box">
      <img class="bitmap" src="@assets/images/bitmap-home.png" alt="" />
      <ul class="data-total">
        <li class="data-total-item">
          <div class="data-total-type">
            <h1 class="pf_bold">
              {{ topData.lesson_rate || 0 }}<span class="pf">%</span>
            </h1>
            <p>教师备课率</p>
          </div>
          <p class="icon">
            <img src="@assets/images/home-icon1.png" alt="" />
          </p>
        </li>
        <li class="data-total-item">
          <div class="data-total-type">
            <h1 class="pf_bold">
              {{ topData.lesson_integrity_rate || 0 }}<span class="pf">%</span>
            </h1>
            <p>教师备课完整率</p>
          </div>
          <p class="icon">
            <img src="@assets/images/home-icon2.png" alt="" />
          </p>
        </li>
        <li class="data-total-item">
          <div class="data-total-type">
            <h1 class="pf_bold">{{ topData.courseware_num || 0 }}</h1>
            <p>课件总数量</p>
          </div>
          <p class="icon">
            <img src="@assets/images/home-icon3.png" alt="" />
          </p>
        </li>
        <li class="data-total-item empty"></li>
        <li class="data-total-item">
          <div class="data-total-type">
            <h1 class="pf_bold">{{ topData.courseware_enjoy_num || 0 }}</h1>
            <p>教师共享课件数量</p>
          </div>
          <p class="icon">
            <img src="@assets/images/home-icon4.png" alt="" />
          </p>
        </li>
        <li class="data-total-item">
          <div class="data-total-type">
            <h1 class="pf_bold">{{ topData.wrong_que_num || 0 }}</h1>
            <p>错题总数量</p>
          </div>
          <p class="icon">
            <img src="@assets/images/home-icon5.png" alt="" />
          </p>
        </li>
        <li class="data-total-item">
          <div class="data-total-type">
            <h1 class="pf_bold">
              {{ topData.wrong_que_reform_rate || 0 }}<span class="pf">%</span>
            </h1>
            <p>错题重做率</p>
          </div>
          <p class="icon">
            <img src="@assets/images/home-icon6.png" alt="" />
          </p>
        </li>
        <li class="data-total-item">
          <div class="data-total-type">
            <h1 class="pf_bold">{{ topData.meeting_num || 0 }}</h1>
            <p>家长会总次数</p>
          </div>
          <p class="icon">
            <img src="@assets/images/home-icon7.png" alt="" />
          </p>
        </li>
      </ul>
      <div class="ranking-table">
        <h4 class="box-head bold">教师备课排行榜</h4>
        <div class="table-box">
          <div class="table-head">
            <h5 style="width: 12%">排行</h5>
            <h5 style="width: 29%">头像</h5>
            <h5 style="width: 28%">教师姓名</h5>
            <h5 style="width: 13%">备课率</h5>
            <h5 style="width: 18%">备课完整率</h5>
          </div>
          <div
            class="table-body"
            ref="tableBox"
            :class="{ scroll: tableScroll, 'no-data': $isEmpty(tableData) }"
            v-loading="dataLoad"
          >
            <template v-if="!$isEmpty(tableData)">
              <ul
                class="table-row pf"
                v-for="(item, index) in tableData"
                :key="index"
              >
                <li class="table-cell">
                  <h4 class="pf_bold">{{ index + 1 }}</h4>
                </li>
                <li class="table-cell">
                  <p
                    class="avatar"
                    :class="[
                      { 'top-three': index < 3 },
                      `ranking-level--${index + 1}`,
                    ]"
                  >
                    <el-avatar
                      fit="cover"
                      :size="58"
                      :src="formatfile(item.teuse_image)"
                    >
                      <img src="@assets/images/empty_avatar.png" />
                    </el-avatar>
                  </p>
                </li>
                <li class="table-cell">
                  <p>{{ item.teuse_name }}</p>
                </li>
                <li class="table-cell">
                  <p>{{ item.class_rate || 0 }}%</p>
                </li>
                <li class="table-cell">
                  <p>{{ item.class_all_rate || 0 }}%</p>
                </li>
              </ul>
            </template>
            <div class="no-data--empty" v-if="$isEmpty(tableData) && !dataLoad">
              <img src="@assets/images/no-data3.png" alt="" />
              <p>暂无排行信息哦~</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <h4 class="box-head">
        <span class="bold">消息通知</span>
        <router-link
          class="view-all"
          :to="{ name: 'NOTIFY' }"
          v-if="messageData.length > 9"
        >
          <span>查看全部</span>
          <span class="iconfont">&#xe601;</span>
        </router-link>
      </h4>
      <div
        class="notify-box"
        :class="{ 'no-data': $isEmpty(messageData) }"
        v-loading="dataLoad"
      >
        <ul class="notify-list" v-if="!$isEmpty(messageData)">
          <li
            class="notify-item"
            v-for="(item, index) in messageData"
            :key="index"
            @click="
              $router.push({
                name: 'NOTIFY_DETAIL',
                params: { id: item.plsch_mes_id },
              })
            "
          >
            <p class="date">
              <span class="iconfont">&#xe61a;</span>
              <span class="date-info">{{ item.create_time }}</span>
            </p>
            <p class="line-text--1st desc">
              <span v-if="item.create_origin < 30"
                >【{{ item.origin_name }}】</span
              >{{ item.plsch_mes_title }}
            </p>
          </li>
        </ul>
        <div class="no-data--empty" v-if="$isEmpty(messageData) && !dataLoad">
          <img src="@assets/images/no-data3.png" alt="" />
          <p>暂无消息通知哦~</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getHomeData, upgradation } from "@api/home";
import { formatFile } from "@utils";
export default {
  name: "home",
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },
  data() {
    return {
      tableScroll: false,
      topData: {}, //顶部数据
      messageData: {}, //消息数据
      tableData: [], // 排行榜数据
      dataLoad: false,
    };
  },

  watch: {},

  created() {
    this.init();
  },

  mounted() {
    this.initHeight();
    /* 监听窗口大小变化 */
    window.addEventListener("resize", this.initHeight);
  },
  /** 监听页面离开 */
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
  /** 销毁页面上窗口监听事件 */
  destroyed() {
    window.removeEventListener("resize", this.initHeight);
  },
  methods: {
    /** 格式化表格最大高度 */
    initHeight() {
      this.$nextTick(() => {
        setTimeout(() => {
          const elH = this.$refs.tableBox;
          if (elH.scrollHeight > elH.clientHeight) this.tableScroll = true;
          else this.tableScroll = false;
          this.$forceUpdate();
        }, 200);
      });
    },
    /** 获取首页数据 */
    async init() {
      this.dataLoad = true;
      const res = await getHomeData();
      this.dataLoad = false;
      if (res.status == 200) {
        this.topData = res.data.overview;
        this.messageData = res.data.message_list;
        this.tableData = res.data.ranking_list;
        if (res.data.is_enter_school == 2) {
          this.$confirm("请确认是否进行学期升级调整？", "提示", {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            callback: () => {},
            beforeClose: (action, ctx, close) => {
              if (action !== 'confirm') {
                close();
                return;
              }
              ctx.confirmButtonLoading = true;
              upgradation().then((res2)=>{
                if (res2.status == 200) {
                  close();
                  this.$message({
                    message: '升级成功',
                    type: 'success'
                  });
                  location.reload();
                }
              })
            }
          }).finally(() => {})
        }
      }
    },
  },
};
</script>
